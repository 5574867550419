import * as React from "react"
import { graphql } from "gatsby"
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { INLINES, BLOCKS, MARKS } from '@contentful/rich-text-types'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import { Container, Section, Flex, Box } from "../components/ui"


import "./contact.css";

const Fallback = (props) => {
  console.warn(`No component found for: ${props.blocktype}`)
  return false
}

export default function About(props) {
  const { contentfulContactPage } = props.data

  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => {
        return <p class="heading-1">{children}</p>
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return <p class="heading-2">{children}</p>
      },
    },
    renderText: text => {
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  }

  return (
    <Layout {...contentfulContactPage}>
      <Section>
        <Container>
          <div className={'contact-container'}>
            <Box className={'contact-image'}>
              <GatsbyImage
                  image={getImage(contentfulContactPage.me.gatsbyImageData)}
              />
            </Box>
            {contentfulContactPage.about && <div className="contact-rich-text">{renderRichText(contentfulContactPage.about, options)}</div>}
          </div>
        </Container>
      </Section>

    </Layout>
  )
}

export const query = graphql`
  {
    contentfulContactPage {
      about {
        raw
      }
      me {
        id
        gatsbyImageData
      }
    }
  }
`
